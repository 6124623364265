import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/ngrx/reducers';
import { AlbumService } from 'src/app/services/album.service';
import { Album, AlbumEditDTO } from '../../models/album.model';
import * as albumActions from '../../ngrx/actions/albums.actions';
import { ShareAlbumDialogComponent } from '../dialogs/share-album-dialog/share-album-dialog.component';
@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss']
})
export class AlbumsComponent implements OnInit {
   sortFormControl: FormControl;
   _albums:Album[];


  @Input()
  get albums(): Album[] {
    return this._albums;
  }
  set albums(value: Album[]) {
    this._albums = value.sort((a, b) => {
      if(a.sort > b.sort) return 1;
      if(b.sort > a.sort) return -1;
      if(a.sort==b.sort){
        if(a.id > b.id) return 1;
        if(b.id > a.id) return -1;
      }
      else return 0;
  });
   
  }
  @Output() selectAlbum: EventEmitter<any> = new EventEmitter();

  @Output() sortUpdate: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router,
    private store$: Store<AppState>,
    public albumDialog: MatDialog,
    private albumService: AlbumService,
    
  ) { }
  trackByIndex(index: number, obj: any): any {
    return index;
  }
  ngOnInit() {
    
  }

  sortChange(event,index,album){
    let dto: AlbumEditDTO = {
      MediaGroupId: album.id,
      Name: album.name,
      Description: album.description,
      CoverImage: album.coverImage,
      UserIds:album.sharedUserIds,
      Sort:+event.target.value
    }

    this.albums[index].sort=event.target.value;
   
    this.store$.dispatch(albumActions.editAlbum({albumEditDTO: dto}));
  }
  viewAlbum(album: Album) {
    this.selectAlbum.emit(album);
  }
  openAlbumSettings(album:Album) {
    //Add user name, and album link to email.
    let editData = {
      name: album.name,
      description: album.description,
      coverImage: album.coverImage,
      userIds:album.sharedUserIds,
      subject:"A media library album is now shared with you.",
      body:` is now sharing the media library album "${album.name}" with you. <br /> Link to album: <a href="${window.location.origin}/album/${album.id}">${album.name}</a>`
    };
    const albumDialogRef = this.albumDialog.open(ShareAlbumDialogComponent,
    {
      //width: '750px',
      data: {
        editData
      }
    });

    albumDialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result && result.users){
        let dto: AlbumEditDTO = {
          MediaGroupId: album.id,
          Name: album.name,
          Description: album.description,
          CoverImage: album.coverImage,
          
          UserIds:result.users.map(u=>u.id),
          Sort:result.Sort
        }
        this.albumService.queueAlbumShareEmail(result.users.map(e=>e.login).join(';'),result.subject,result.body).subscribe();
        this.store$.dispatch(albumActions.editAlbum({albumEditDTO: dto}));
      }
    
    })
  }

  
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/ngrx/reducers";
import { Gallery, GalleryEditDTO } from "../../../models/gallery.model";
import { Office } from "../../../models/office.model";
import * as galleryActions from '../../../ngrx/actions/galleries.actions';
@Component({
  selector: "app-galleries",
  templateUrl: "./galleries.component.html",
  styleUrls: ["./galleries.component.scss"]
})
export class GalleriesComponent implements OnInit {


  _galleries:Gallery[];


  @Input()
  get galleries(): Gallery[] {
    return this._galleries;
  }
  set galleries(value: Gallery[]) {
    this._galleries = value.sort((a, b) => {
      if(a.sort > b.sort) return 1;
      if(b.sort > a.sort) return -1;
      if(a.sort==b.sort){
        if(a.id > b.id) return 1;
        if(b.id > a.id) return -1;
      }
      else return 0;
  });
   
  }
  @Input() offices: Office[];
  @Input() userOfficeId: number;
  @Input() showStatusFilter: boolean;
  @Output() selectGallery: EventEmitter<any> = new EventEmitter();
  @Output() sortUpdate: EventEmitter<any> = new EventEmitter();

  officeFormControl = new FormControl();
  statusFormControl = new FormControl();
  officeId;

  currentStatus: string;
  filteredGalleries: Gallery[] = [];

  // status model for gallery status drop down
  public statusModel = [
    { statusName: "Draft", id: "DFT" },
    { statusName: "Published", id: "PBH" },
    { statusName: "Archived", id: "ARC" }
  ];
  constructor(  private store$: Store<AppState>) {}

  trackByIndex(index: number, obj: any): any {
    return index;
  }


  ngOnInit() {
    this.selectedGalleryStatus = "PBH"; // default value is published
    this.officeFormControl.setValue(this.userOfficeId);
    this.statusFormControl.setValue(this.currentStatus);
    this.selectedOfficeValue = this.userOfficeId;
    this.filterGalleries(this.selectedOfficeValue, this.selectedGalleryStatus);
  }
  ngOnChanges(val) {
    this.filterGalleries(this.selectedOfficeValue, this.selectedGalleryStatus);
  }
  viewGallery(gallery: any) {
    this.selectGallery.emit(gallery);
  }
  filterGalleries(officeId: number, statusCode: string) {
    if (this.galleries && this.galleries.length) {
      this.filteredGalleries = this.galleries.filter(
        g => g.officeId === officeId && g.statusCode == statusCode
      );
    }
  }

  // ------------------------ Setter for Office---------------
  public set selectedOfficeValue(value) {
    if (value !== undefined && this.officeId !== value) {
      this.officeId = value;
      this.filterGalleries(value, this.selectedGalleryStatus);
    }
  }

  // -------------------------- Getter for Office -------------------------------------
  public get selectedOfficeValue() {
    return this.officeId;
  }
  //--------------------------- Setter for gallery status -------------------------------
  public set selectedGalleryStatus(value: string) {
    if (value !== undefined && this.currentStatus != value) {
      this.currentStatus = value;
      this.filterGalleries(this.selectedOfficeValue, value);
    }
  }
  // ---------------------- Getter for gallery status ------------------------------------
  public get selectedGalleryStatus() {
    return this.currentStatus;
  }
  sortChange(event,album,index){
    let dto: GalleryEditDTO = {
      Name: album.name,
      Description: album.description,
      CoverImage: album.coverImage,
      OfficeId: album.officeId,
      MediaGroupId: album.id,
      Sort:+event.target.value
    }
    
    this.filteredGalleries[index].sort=event.target.value;
    this.filteredGalleries.sort((a, b) => {
      if(a.sort > b.sort) return 1;
      if(b.sort > a.sort) return -1;
      if(a.sort==b.sort){
        if(a.id > b.id) return 1;
        if(b.id > a.id) return -1;
      }
      else return 0;
  });
  this.store$.dispatch(galleryActions.editGallery({galleryEditDTO: dto}));
   //this.sortUpdate.emit(album);
  }
}

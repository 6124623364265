import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { ContentCreateDialogComponent } from '../../components/dialogs/content-create-dialog/content-create-dialog.component';
import { ContentDeleteDialogComponent } from '../../components/dialogs/content-delete-dialog/content-delete-dialog.component';
import { Album, AlbumEditDTO } from '../../models/album.model';
import { Media } from '../../models/media.model';
import * as albumActions from '../../ngrx/actions/albums.actions';
import { getMediaByAlbumId } from '../../ngrx/actions/media.actions';
import { AppState } from '../../ngrx/reducers';
import { getMediaFromAlbum, selectUserAlbums } from '../../ngrx/selectors/albums.selector';



@Component({
  selector: 'album-view',
  templateUrl: './album-view.component.html',
  styleUrls: ['./album-view.component.scss']
})
export class AlbumViewComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  public albums$: Observable<Album[]>;
  public media$: Observable<Media[]>;
  mediaGallery: Media[] = [];
  albums: Album[];
  loggedIn=false;
  loadingMedia=false;
  public album: Album={ id: null,
    name: '',
    description: '',
    coverImage: '',
    coverImageUrl: '',
    isPublic: false,
    type: '',
    totalMediaCount: 0,
    isFavorite: false,
    sharedUserIds:[]
    };
  multiSelect = false;
  albumId: number;
  public selectedImageIds: number[];
  public imagesAreSelectable: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private store$: Store<AppState>,
    private deleteDialog: MatDialog,
    public albumDialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    const loggedIn$ = this.store$.pipe( takeUntil(this.unsubscribe$),select(state => state.user)
    , skipWhile(res => res.login === null || res.login === false || res.user=== null || res.user.userLoginId === null || res.user.userLoginId===0));
    loggedIn$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.store$.dispatch(albumActions.getUserAlbums());
      this.loggedIn=true;
      this.route.params.pipe(
        takeUntil(this.unsubscribe$)).subscribe(params => {
          this.albumId = params.id;
          this.albums$ = this.store$.pipe(
            select(selectUserAlbums),
            takeUntil(this.unsubscribe$));
  
          this.albums$.pipe(takeUntil(this.unsubscribe$)).subscribe(albums => {
           
            if (albums && albums.length > 0) {
              this.unsubscribe();
              this.albums = albums;
              let userAlbum = albums.find(a => a.id === +this.albumId);
              if (userAlbum) {
                this.album = userAlbum;
                this.store$.dispatch(getMediaByAlbumId({ albumId: this.album.id, pageSize: 100 }));
                this.media$ = this.store$.pipe( takeUntil(this.unsubscribe$), select(getMediaFromAlbum, { albumId: this.album.id }))
                this.media$.subscribe(res => {
                  this.mediaGallery = res;
                  this.unsubscribe();
                  if (res.length>0){
                    this.loadingMedia=true;
                  }else{
                    this.loadingMedia=false;
                  }
                
                })
              };
            }
          });
  
        });
    });
  
  };

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectMultiple() {
    this.multiSelect = !this.multiSelect;
  }


  deleteAlbum(): void {
    const deleteDialogRef = this.deleteDialog.open(ContentDeleteDialogComponent, {
      width: '500px',
    });

    deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store$.dispatch(albumActions.deleteAlbum({id: this.album.id}));
        this.router.navigate(['/users-content']);
      }
    });
  }

  onImageSelected($event) {
    if($event.selected) {
      this.selectedImageIds.push($event.mediaId);
    } else {
      this.selectedImageIds = this.selectedImageIds.filter(i => i != $event.mediaId);
    }
  }

  toggleSelectionMode() {
    this.imagesAreSelectable = !this.imagesAreSelectable;
    this.selectedImageIds = [];
  }

  removeSelectedMedia() {
    this.store$.dispatch(albumActions.bulkRemoveMediaFromAlbum({mediaIds: this.selectedImageIds, albumId: this.album.id}))
    this.selectedImageIds = [];
    this.imagesAreSelectable = false;
  }

  editAlbumDialog() {
    let editData = {
      name: this.album.name,
      description: this.album.description,
      coverImage: this.album.coverImage,
      sort:this.album.sort
    };
    const albumDialogRef = this.albumDialog.open(ContentCreateDialogComponent,
    {
      width: '700px',
      data: {
        isAlbum: true,
        pocket: this.mediaGallery,
        editData: editData,
        albums: this.albums
      }
    });

    albumDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      let dto: AlbumEditDTO = {
        MediaGroupId: this.album.id,
        Name: result.Name,
        Description: result.Description,
        CoverImage: result.CoverImage,
        UserIds:this.album.sharedUserIds,
        Sort:result.Sort
      }
      if (result){
        this.store$.dispatch(albumActions.editAlbum({albumEditDTO: dto}));
        this.album.name=result.Name;
        this.album.description=result.Description;
      }
     
      
    })
  }

  updateAlbumStatus()
  {
    this.store$.dispatch(albumActions.updateAlbumStatus({id: this.album.id, isPublic: !this.album.isPublic}));
  }
}

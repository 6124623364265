import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { dashboardResult } from '../models/dashboardResult.model';
import { GalleryEditDTO } from '../models/gallery.model';
import { searchResult } from '../models/searchResult.model';

export class GalleryCreateCommand {
  Name: string;
  Description: string;
  StatusId: number;
  IsPublic: Boolean;
  CoverImage: string;
  MediaId: number;
  CreatedByUserId: number;
  UpdatedByUserId: number;
  OfficeId: number;
  MediaIds:number[];
  Sort:number;
}

export class GalleryResult {
  name: string;
  description: string;
  id: number;
  isPublic: boolean;
  status: number;
  statusCode: string;
  totalMediaCount: number;
  coverImage: string;
  coverImageUrl: string;
  officeId: number;
  officeName: string;
  createdByUserId: number;
  createdByUserName: string;
  isShared?:boolean;
sharedUserIds?:number[];
userId?:number;
}

export class GallerySearchCommand {
  Id?: number;
  OfficeId?: number;
  IncludeAllOffices?: boolean;
  PageSize?: number;
  IsPublic?: boolean;
  CreatedByUserId?: boolean;
  StatusCode?: string;
}
const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient) { }

  /**
   * This is being used as a bad state container. It is the pattern used throughout the app, and realistically
   * this app should probably have a legitimate state solution, but I didn't have time to implement that all now
   * so I'm just following existing patterns here for consistency.
   */
  public currentGallery: GalleryResult;

  getGalleriesByUser(userId: number) {
    const url = `${environment.mediaApiEndpoint}Gallery?&CreatedByUserId=${userId}&IncludeAllOffices=true`;
    return this.http.get<GalleryResult[]>(url);
  }

  getGalleries(searchCommand: GallerySearchCommand) {
    let url = `${environment.mediaApiEndpoint}Gallery?`;
    const args = Object.entries(searchCommand);
    for (const [key, value] of args) {
      url += `&${key}=${value}`;
    }
    return this.http.get<GalleryResult[]>(url);
  }
  getGalleriesByUserAccess(userId: number) {
    const url = `${environment.mediaApiEndpoint}Gallery/UserAccess?UserId=${userId}`;
    return this.http.get<GalleryResult[]>(url);
  }
  getMediaByGallery(galleryId: number, pageSize: number) {
    const url = `${environment.mediaApiEndpoint}Gallery/` + galleryId + '/Media?count=' + pageSize;
    return this.http.get<searchResult[]>(url);
  }
  getTopViewdGalleriesByOffice(officeId: number, pageSize: number) {
    if (officeId !== undefined && officeId > 0) {
      const url = `${environment.mediaApiEndpoint}Gallery?&IncludeAllOffices=true&OfficeId=` + officeId + '&PageSize=' + pageSize;
      return this.http.get<dashboardResult[]>(url);
    }
  }
  getAllGalleries(pageSize: number) {
    const url = `${environment.mediaApiEndpoint}Gallery?&IncludeAllOffices=true&PageSize=` + pageSize;
    return this.http.get<dashboardResult[]>(url);
  }
  getGalleriesByOffice(officeId: number, pageSize: number) {
    if (officeId !== undefined && officeId > 0) {
      const url = `${environment.mediaApiEndpoint}Gallery?StatusCode=PBH&OfficeId=` + officeId + '&PageSize=' + pageSize;
      return this.http.get<GalleryResult>(url);
    }
  }
  createGallery(gallery: GalleryCreateCommand) {
    const url = `${environment.mediaApiEndpoint}Gallery`;
    return this.http.post<GalleryResult>(url, gallery);
  }
  editGallery(dto: GalleryEditDTO) {
    const url = `${environment.mediaApiEndpoint}Gallery`;
    return this.http.patch<GalleryResult>(url, dto);
  }
  updateGalleryStatus(id: number, statusId: number)
  {
    const url = `${environment.mediaApiEndpoint}Gallery/${id}/${statusId}`;
    return this.http.patch<boolean>(url, {});
  }
}

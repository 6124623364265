<div class="page-header-row page-header-row-right">
  <div class="page-context-wrap">
    <div class="page-context">
      <mat-select
        *ngIf="showStatusFilter === true"
        class="page-context-filter filter"
        placeholder="Status"
        [(ngModel)]="selectedGalleryStatus"
        name="statusModel"
        [formControl]="statusFormControl"
      >
        <mat-option *ngFor="let status of statusModel" [value]="status.id">
          {{ status.statusName }}
        </mat-option>
      </mat-select>
      <mat-select
        class="page-context-filter filter"
        placeholder="Office"
        [(ngModel)]="selectedOfficeValue"
        name="officeModel"
        [formControl]="officeFormControl"
      >
        <mat-option *ngFor="let officeModel of offices" [value]="officeModel.officeId">
          {{ officeModel.officeName }}
        </mat-option>
      </mat-select>
    </div>
  </div>
</div>
<div class="media-grid-wrap">
  <!-- loop through user's galleries in folder-item-wrap class -->
  <div *ngFor="let gallery of filteredGalleries; let i = index; trackBy: trackByIndex" class="folder-item-wrap">
    <img alt="cover" class="folder-item-image" [src]="gallery.coverImageUrl" />

    <div class="folder-hover">
      <button class="button-border-white folder-hover-button" (click)="viewGallery(gallery)">View Gallery</button>
    </div>

    <div class="folder-meta-wrap">
      <div class="folder-meta-info">
        <div class="folder-meta-title">{{ gallery.name }}</div>
        <div class="folder-meta-created">{{ gallery.createdByUserName }}</div>
      </div>

      <div class="count">
        <div class="folder-meta-count">
          <!-- <mat-form-field style="width: 55px"> -->
          <mat-label>Count</mat-label>
          <input title="count" matInput maxlength="3" [value]="gallery.totalMediaCount" readonly />
          <!-- </mat-form-field> -->
        </div>
      </div>
      <div class="sort">
        <div class="folder-meta-count">
          <mat-form-field style="width: 30px; height: 30px">
            <mat-label>Sort</mat-label>
            <input title="sort" matInput maxlength="3" [value]="gallery.sort" (change)="sortChange($event, gallery, i)" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

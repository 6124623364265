import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlbumEditDTO } from 'src/app/models/album.model';
import { AppState } from 'src/app/ngrx/reducers';
import { dashboardResult } from '../../models/dashboardResult.model';
import { MediaGroupType } from '../../models/media-group-type.enum';
import { GalleryResult } from '../../services/gallery.service';
import { ShareAlbumDialogComponent } from '../dialogs/share-album-dialog/share-album-dialog.component';

import { AlbumService } from 'src/app/services/album.service';
import { UserService } from 'src/app/services/user.service';
import * as albumActions from '../../ngrx/actions/albums.actions';
//import { ShareAlbumDialogComponent } from '../dialogs/share-album-dialog/share-album-dialog.component';
@Component({
  selector: 'app-collection-tile',
  templateUrl: './collection-tile.component.html',
  styleUrls: ['./collection-tile.component.scss']
})
export class CollectionTileComponent implements OnInit {

  @Input() created: any;
  @Input() title: any;
  @Input() count: any;
  @Input() type: any;
  @Input() thumbNailUrl: any;
  @Input() id: any;
  @Input() album: dashboardResult | GalleryResult;
  private unsubscribe$ = new Subject<void>();
isAblum=false;
canEditShare=false;
  constructor(private router: Router,  public albumDialog: MatDialog
    ,private store$: Store<AppState>,
    private albumService: AlbumService,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.isAblum=this.type === MediaGroupType.ALBUM;
    this.store$
    .pipe(
      take(1),
      select(state => state.user)
    )
    .subscribe(res => {
      this.canEditShare=this.isAblum && this.album.userId===res.user.userLoginId;
    });
    
  }

  viewMediaList() {
    if (this.type === MediaGroupType.ALBUM) {
      this.router.navigate([`/album/${this.album.id}`]);
    }
    else if (this.type === MediaGroupType.GALLERY) {
      this.router.navigate([`/gallery/${this.album.id}`]);
    }
  }

  openAlbumSettings() {
    //Add user name, and album link to email.
    let editData = {
      name: this.album.name,
      description: this.album.description,
      coverImage: this.album.coverImage,
      userIds:this.album.sharedUserIds,
      subject:"A media library album is now shared with you.",
      body:` is now sharing the media library album "${this.album.name}" with you. <br /> Link to album: <a href="${window.location.origin}/album/${this.album.id}">${this.album.name}</a>`
    };
    const albumDialogRef = this.albumDialog.open(ShareAlbumDialogComponent,
    {
      //width: '750px',
      data: {
        editData
      }
    });

    albumDialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result && result.users){
        let dto: AlbumEditDTO = {
          MediaGroupId: this.album.id,
          Name: this.album.name,
          Description: this.album.description,
          CoverImage: this.album.coverImage,
          
          UserIds:result.users.map(u=>u.id),
          Sort:result.Sort
        }
        this.albumService.queueAlbumShareEmail(result.users.map(e=>e.login).join(';'),result.subject,result.body).subscribe();
        this.store$.dispatch(albumActions.editAlbum({albumEditDTO: dto}));
      }
    
    })
  }

}

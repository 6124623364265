import { ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { dashboardResult } from '../../../models/dashboardResult.model';
import { Media } from '../../../models/media.model';
import { Office } from '../../../models/office.model';
import { GalleryCreateCommand } from '../../../services/gallery.service';

@Component({
  selector: 'app-album-dialog',
  templateUrl: './content-create-dialog.component.html',
  styleUrls: ['./content-create-dialog.component.scss'],
})
export class ContentCreateDialogComponent implements OnInit {
  @HostBinding('class.content-type')
  officemodels: Office[];
  selectedOffice: Office;

  Name: string;
  Sort:number=99;
  Description: string;

  isAlbum = false;
  typeString = 'Gallery';
  actionString = 'Create New';

  pocket: Media[];
  selectedImage: Media;
  formIsValid = false;
  albums: dashboardResult[];

  originalAlbumName: string = null; // Necessary so we don't whine at a user for not altering an album name on edit

  // Error Object
  errors = {
    name: {
      hasError: false,
      message: '',
    },
  };

  constructor(
    public cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ContentCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    // Common Properties
    this.isAlbum = data.isAlbum;
    this.pocket = data.pocket;
    // Album specific
    if (this.isAlbum) {
      this.typeString = 'Album';
      this.albums = data.albums;
    }
    // Gallery specific
    else {
      this.officemodels = data.offices;
    }

    if(data.editData) {
      this.actionString = "Edit"
      this.Name = data.editData.name;
      this.Sort=data.editData.sort;
      if(this.isAlbum) {
        this.originalAlbumName = data.editData.name;
      }
      this.Description = data.editData.description;
      let coverImage = data.pocket.filter(i => i.fileName_Thumbnail == data.editData.coverImage)
      if(coverImage.length) {
        this.selectedImage = coverImage[0];
      }
      if(!this.isAlbum){
        let office = this.officemodels.filter(o => o.officeId == data.editData.officeId);
        if(office.length) {
          this.selectedOffice = office[0];
        }
      }
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }
  ngOnInit() {}

  onParameterChange() {
    this.isFormValid();
  }

  isFormValid() {
    // Validate that the name is present and properly unique
    if (this.Name) {
      this.errors.name.hasError = false;
      this.errors.name.message = '';
      // Album name validation
      if (this.isAlbum) {
        const matchedAlbumName = this.albums.filter(a => a.name === this.Name);
        if (matchedAlbumName.length && matchedAlbumName[0].name != this.originalAlbumName) {
          this.errors.name.hasError = true;
          this.errors.name.message = `You already have an Album called ${this.Name}. Please choose a new name.`;
          this.formIsValid = false;
          return;
        }
      }
    }

    // Finally validate all required fields are present
    if (this.Name && this.Description && this.selectedImage 
      && ((!this.isAlbum && this.selectedOffice) || this.isAlbum)) {
      this.formIsValid = true;
    } else {
      this.formIsValid = false;
    }

    this.cdRef.markForCheck();
  }

  selectCoverImage(image: Media) {
    this.selectedImage = image;
    this.isFormValid();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (!this.formIsValid) {
      return;
    }
    if (this.isAlbum) {
      this.submitAlbum();
    } else {
      this.submitGallery();
    }
  }

  submitGallery() {
    let result: GalleryCreateCommand = new GalleryCreateCommand();
    result.Name = this.Name;
    result.Description = this.Description;
    if (this.selectedImage.fileName_Thumbnail){
      result.CoverImage = this.selectedImage.fileName_Thumbnail;
    }else{
      result.CoverImage = this.selectedImage.thumbnailFileName;
    }
   
    result.MediaId = +this.selectedImage.mediaId;
    result.MediaIds=[+this.selectedImage.mediaId];
    result.OfficeId = this.selectedOffice.officeId;
    result.Sort=+this.Sort;
    this.dialogRef.close(result);
  }

  submitAlbum() {
    const result = {
      Name: this.Name,
      Description: this.Description,
      CoverImage: this.selectedImage.fileName_Thumbnail ?this.selectedImage.fileName_Thumbnail:this.selectedImage.thumbnailFileName ,
      MediaId: +this.selectedImage.mediaId,
      Sort:+this.Sort
    };
    this.dialogRef.close(result);
  }
}

<div class="media-grid-wrap">
  <!-- loop through user's albums-->
  <div *ngFor="let album of albums; let i = index; trackBy: trackByIndex" class="folder-item-wrap">
    <img alt="cover" class="folder-item-image" [src]="album.coverImageUrl" />

    <div class="folder-hover">
      <button class="button-border-white folder-hover-button" (click)="viewAlbum(album)">View Album</button>
      <button class="button-border-white folder-hover-button2" (click)="openAlbumSettings(album)">Share Settings</button>
    </div>

    <div class="folder-meta-wrap">
      <div class="folder-meta-info">
        <div class="folder-meta-title">{{ album.name }}<mat-icon *ngIf="album.sharedUserIds?.length > 0">folder_shared</mat-icon></div>
        <div class="folder-meta-created">{{ album.description }}</div>
      </div>

      <div class="folder-meta-count">
        <!-- <mat-form-field style="width: 55px"> -->
        <mat-label class="label">Count</mat-label>
        <input title="count" matInput maxlength="3" [value]="album.totalMediaCount" readonly />
        <!-- </mat-form-field> -->
      </div>

      <div class="folder-sort">
        <mat-form-field style="width: 30px; height: 30px">
          <mat-label class="label">Sort</mat-label>
          <input title="sort" matInput maxlength="3" [value]="album.sort" (change)="sortChange($event, i, album)" />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

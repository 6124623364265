import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SwiperCore, { Keyboard, Navigation, Pagination, Virtual } from 'swiper';
import { ContentCreateDialogComponent } from '../../components/dialogs/content-create-dialog/content-create-dialog.component';
import { Gallery, GalleryEditDTO } from '../../models/gallery.model';
import { Media } from '../../models/media.model';
import * as MLStatusMapping from '../../models/status.model';
import * as galleryActions from '../../ngrx/actions/galleries.actions';
import { getMediaByGalleryId } from '../../ngrx/actions/media.actions';
import { AppState } from '../../ngrx/reducers';
import { selectGalleryById, selectMediaForGallery } from '../../ngrx/selectors/galleries.selector';
import { selectUserOffices } from '../../ngrx/selectors/offices.selector';
SwiperCore.use([Keyboard,Pagination,Navigation,Virtual]);
@Component({
  selector: 'app-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GalleryViewComponent implements OnInit {

  galleryId: number;

  gallery: Gallery;
  galleryMediaIds$: Observable<(number | string)[]>;
  mediaInGallery: Media[];
  private unsubscribe$ = new Subject<void>();
  private gallery$: Observable<Gallery>;
  public galleryMedia$: Observable<Media[]>;
  public galleryMedia: Media[];
  public selectedImageIds: number[] = [];
  public imagesAreSelectable: boolean = false;
  offices$: Observable<any[]>;
  public offices;
  public galleryStatusActionString: string = '';
  public test;

  constructor(
    public deleteDialog: MatDialog,
    public galleryDialog: MatDialog,
    private route: ActivatedRoute,
    private store$: Store<AppState>
  ) { }

  ngOnInit() {
    this.mediaInGallery = [];
    this.store$.dispatch(galleryActions.getUserGalleries());
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.gallery$ = this.store$.pipe(select(selectGalleryById, { galleryId: params.galleryId }), takeUntil(this.unsubscribe$));
      this.gallery$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
          //Handle status strings
          if(res){
            let statusObject: MLStatusMapping.StatusObjects = MLStatusMapping.getStatusObjByCode(res.statusCode);
            if(statusObject.description == "Draft"){
              this.galleryStatusActionString = "Publish Gallery";
            } else if(statusObject.description == "Published") {
              this.galleryStatusActionString = "Archive Gallery";
            } else {
              this.galleryStatusActionString = "Set to Draft";
            }

            this.gallery = res;
            this.test = res.status;
            this.store$.dispatch(getMediaByGalleryId({ galleryId: res.id, pageSize: 100 }));
            this.galleryMedia$ = this.store$.pipe(takeUntil(this.unsubscribe$), select(selectMediaForGallery, { galleryId: this.gallery.id }));
            this.galleryMedia$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
              this.galleryMedia = res;
            })
          }
        })

      this.offices$ = this.store$.pipe(select(selectUserOffices));
      this.offices$.pipe(takeUntil(this.unsubscribe$)).subscribe(o => this.offices = o)
    });

  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateGalleryStatus(){
    let statusObj = MLStatusMapping.getStatusObjByCode(this.gallery.statusCode);
    let statusId = statusObj.id + 1;
    //Only awkard part here. If our status is past "archived", then we roll back to "draft".
    //Sorry about the numbers....
    if(statusId > 6) {
      statusId = 4;
    }
    //Note we roll forward 1 more because we are incrementing status and the obj we have is current status not next
    if(statusObj.description == "Draft"){
      this.galleryStatusActionString = "Archive Gallery";
    } else if(statusObj.description == "Published") {
      this.galleryStatusActionString = "Set to Draft";
    } else {
      this.galleryStatusActionString = "Publish Gallery";
    }
    this.store$.dispatch(galleryActions.updateGalleryStatus({id: this.gallery.id, statusId: statusId}));
  }

  onImageSelected($event) {
    if($event.selected) {
      this.selectedImageIds.push($event.mediaId);
    } else {
      this.selectedImageIds = this.selectedImageIds.filter(i => i != $event.mediaId);
    }
  }

  toggleSelectionMode() {
    this.imagesAreSelectable = !this.imagesAreSelectable;
    this.selectedImageIds = [];
  }

  removeSelectedMedia() {
    this.store$.dispatch(galleryActions.bulkRemoveMediaFromGallery({mediaIds: this.selectedImageIds, galleryId: this.gallery.id}))
    this.selectedImageIds = [];
    this.imagesAreSelectable = false;
  }

  editGalleryDialog(): void {

    let editData = {
      name: this.gallery.name,
      description: this.gallery.description,
      coverImage: this.gallery.coverImage,
      officeId: this.gallery.officeId
    }
    const galleryDialogRef = this.galleryDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: false,
        pocket: this.galleryMedia,
        offices: this.offices,
        editData: editData
      }
    });

    galleryDialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      if (result) {
        let dto: GalleryEditDTO = {
          Name: result.Name,
          Description: result.Description,
          CoverImage: result.CoverImage,
          OfficeId: result.OfficeId,
          MediaGroupId: this.gallery.id,
          Sort:+result.Sort
        }
        this.store$.dispatch(galleryActions.editGallery({galleryEditDTO: dto}));
      }
    })
  }

}
